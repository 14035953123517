import React from 'react';

const PortfolioDetailsContent = ({title, category, description, meta}) => {
    return (
        <div className="portfolio-details-content">

            <p className="category">{category}</p>

            <h1 className="title">{title}</h1>

            <p>{description}</p>

            <ul className="project-info">
                {meta.availability && <li><span>Availability:</span> {meta.availability}</li>}
                {meta.edition && <li><span>Edition:</span> {meta.edition}</li>}
                {meta.client && <li><span>Client:</span> {meta.client}</li>}
                <li><span>Size:</span> {meta.size}</li>
                <li><span>Date:</span> {meta.date}</li>
                <li><span>Location:</span> {meta.location}</li>
                {meta.website && <li><span>Link:</span>
                    <a href={meta.website}
                       target="_blank"
                       rel="noopener noreferrer">{meta.website}
                    </a>
                </li>}
                <li>
                    <span>Materials:</span>
                    <ul className="materials">
                        {meta.materials.map((m,index) => (
                            <li key={index}>{m}</li>
                        ))}
                    </ul>
                </li>
            </ul>

            <div className="project-share">
                <span>Share:</span>
                <a href="https://www.facebook.com/sharer/sharer.php"><i className="fa fa-facebook"/></a>
                <a href="https://twitter.com/share"><i className="fa fa-twitter"/></a>
                {/*<a href="/"><i className="fa fa-pinterest-p"/></a>*/}
                <a
                    href="https://www.pinterest.com/pin/create/button/"
                    data-pin-do="buttonBookmark"
                    data-pin-custom="true"
                >
                    <i className="fa fa-pinterest-p"/>
                </a>

            </div>

        </div>
    );
};

export default PortfolioDetailsContent;
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import socialIcons from "../../../data/SocialNetworks/index.json";

const FooterThree = ({position}) => {
    return (
        <div className={`footer-section section position-${position} pt-30 pb-30`}>
            <Container fluid>
                <Row className={'align-items-center'}>
                    <Col className={'footer-copyright col-lg-6 col-12 order-lg-1 order-2'}>
                        <p>&copy; {new Date().getFullYear()} Rio Skor. All Rights Reserved</p>
                    </Col>
                    <Col className={'footer-social col-lg-6 col-12 order-lg-2 order-1'}>
                        {socialIcons.map(social=>(
                            <a key={social.id}
                               href={`https://${social.media}.com/${social.username}`}
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <i className={`fa fa-${social.media}`}/>
                            </a>
                        ))}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FooterThree;

import React from 'react';
import socialIcons from "../../../data/SocialNetworks/index.json";

const FooterOne = ({next, prev}) => {
    return (
        <div className="footer-section position-fixed section pt-30 pb-30">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="footer-copyright col-lg-4 col-12 order-lg-1 order-3">
                        <p>&copy; {new Date().getFullYear()} Rio Skor. All Rights Reserved</p>
                    </div>

                    <div className="col-lg-4 col-12 order-lg-2 order-2 mb-sm-15 mb-xs-15">
                        <div className="portfolio-slider-5-nav">
                            <button
                                type="button"
                                className="slick-prev slick-arrow"
                                onClick={prev}> prev
                            </button>
                            <button
                                type="button"
                                className="slick-next slick-arrow"
                                onClick={next}>next
                            </button>
                        </div>
                    </div>

                    <div className="footer-social col-lg-4 col-12 order-lg-3 order-3 mb-sm-15 mb-xs-15">
                        {/*<form action="#">
                            <input type="text" placeholder="SEARCH NOW"/>
                            <button><i className="fa fa-search"/></button>
                        </form>*/}
                        {socialIcons.map(social=>(
                            <a key={social.id}
                               href={`https://${social.media}.com/${social.username}`}
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <i className={`fa fa-${social.media}`}/>
                            </a>
                        ))}
                    </div>

                    <div className="text-center d-lg-none col-12 order-1 mb-sm-15 mb-xs-15">
                        <p>Click on images to see more</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterOne;

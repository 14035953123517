import React from 'react';
import PageContainer from "../../container/CustomPage";
import Statement from "../../container/statement";

const ContentStatementPage = () => {
    return (
        <PageContainer classes={'bg-grey'}>
           <Statement type={'page'}/>
        </PageContainer>
    );
};

export default ContentStatementPage;
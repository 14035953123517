import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Thumbnail from "../../components/thumbnail";
import Content from "../../components/content";
//import Skill from "../../components/skill";
import aboutData from '../../data/AboutMe'

const AboutMe = ({type}) => {
    return (
        <div className={type !== 'page' ? 'section-padding section' : null}>
            <Container>
                <Row className={'align-items-center'}>
                    <Col lg={6}>
                        <Thumbnail classes={'about-thumbnail mb-md-30 mb-sm-30 mb-xs-30'} thumb={`about/${aboutData.thumb}`}/>
                    </Col>

                    <Col lg={6}>
                        <Content classes={'about-content'}>
                            {/*{type !== 'page' ? (*/}
                                <h3 className="block-title">ABOUT</h3>
                            {/*) : null}*/}
                            <p>{aboutData.bio}</p>
                            {aboutData.bio2 && <p>{aboutData.bio2}</p>}
                            {aboutData.bio3 && <p>{aboutData.bio3}</p>}
                            <ul className="personal-info">
                                <li>
                                    <span>Instagram:</span>
                                    <a href="https://www.instagram.com/skorrio/" rel="noreferrer" target="_blank">@{aboutData.insta}</a>
                                </li>
                                {/*<li><span>Freelance:</span>{aboutData.availability ? 'Available' : 'Not Available'}</li>
                                <li>
                                    <span>Languages:</span>
                                    {aboutData.languages.map((language, index) => (
                                        <i key={index}>{`${language}, `}</i>
                                    ))}
                                </li>*/}
                            </ul>

                            {/*<h3 className="block-title">Mediums </h3>

                            <div className="skill-wrap">
                                {aboutData.skills.map(skill => (
                                    <Skill
                                        key={skill.id}
                                        title={skill.title}
                                        percentage={skill.percentage}
                                    />
                                ))}
                            </div>*/}
                        </Content>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutMe;
import React from 'react';
import Input from "../input";

const Form = () => {
    return (
        <form id="contact-form" action="https://formspree.io/f/xzbkjpva" method="post">
            <Input type={'text'} id={'name'} name={'name'} placeholder={'Name'} required={true}/>
            <Input type={'email'} id={'email'} name={'_replyto'} placeholder={'Email'} required={true}/>
            <Input type={'textarea'} id={'message'} name={'message'} rows={'9'} placeholder={'Message'} required={true}/>
            <button type={'submit'}>Send</button>
            <input type="hidden" name="_next" value="//rioskor.com/thankyou"/>
            <input type="hidden" name="_subject" value="Website form submission"/>
        </form>
    );
};

export default Form;

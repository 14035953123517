import React from 'react';
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import FooterThree from "../../components/footer/FooterThree";
import ContentStatementPage from "../../templates/StatementPage";

const StatementPage = () => {
    return (
        <div className={`main-wrapper`}>
            <Header/>
            <SideHeader mobile={true}/>
            <ContentStatementPage />
            <FooterThree position={'fixed'}/>
        </div>
    );
};

export default StatementPage;
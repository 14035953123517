import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Thumbnail from "../../components/thumbnail";
import Content from "../../components/content";
import statementData from '../../data/statement';

const Statement = ({type}) => {
    return (
        <div className={type !== 'page' ? 'section-padding section' : null}>
            <Container>
                <Row className={'align-items-center'}>
                    <Col lg={6}>
                        <Thumbnail classes={'about-thumbnail mb-md-30 mb-sm-30 mb-xs-30'} thumb={`about/${statementData.thumb}`}/>
                    </Col>

                    <Col lg={6}>
                        <Content classes={'about-content'}>
                            {type !== 'page' ? (
                                <h3 className="block-title">STATEMENT</h3>
                            ) : null}
                            <p>{statementData.bio}</p>
                            {statementData.bio2 && <p>{statementData.bio2}</p>}
                            {statementData.bio3 && <p>{statementData.bio3}</p>}
                        </Content>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Statement;
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageContainer from "../../../container/CustomPage";
import PortfolioDetailsThumb from "./thumbnail";
import PortfolioDetailsContent from "./content";
import portfolioDetails from '../../../data/portfolio/details';
import { useParams, Redirect } from "react-router-dom";

const TemplatePortfolioDetails = () => {
    const { slug } = useParams()
    const portfolioDetailsData = portfolioDetails.find(portfolio => portfolio.slug === slug)

    if (!portfolioDetailsData) {
        return <Redirect to="/" />
    }

    return (
        <PageContainer classes={'bg-grey'}>
            <Container>
                <Row>
                    <Col lg={7}>
                        <PortfolioDetailsThumb images={portfolioDetailsData.previewImages} slug={slug}/>
                    </Col>

                    <Col lg={5}>
                        <PortfolioDetailsContent
                            title={portfolioDetailsData.title}
                            category={portfolioDetailsData.category}
                            description={portfolioDetailsData.description}
                            meta={portfolioDetailsData.meta}
                        />
                    </Col>
                </Row>
            </Container>
        </PageContainer>
    );
};

export default TemplatePortfolioDetails;
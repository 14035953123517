import React from 'react';
import {Link} from "react-router-dom";
import tyImage from '../../assets/images/rio-skor-sunbeam-wip-table.png'

const ThankyouPage = () => {
    return (
        <div className="content-body section">
            <div className="section-wrap section error-404-wrap d-flex align-items-center bg-grey">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="error-404-image col-md-6 col-12 order-1 order-md-2 mb-xs-50">
                            {/*<img src={errorThumb} alt="404 Error"/>*/}
                            <img src={tyImage} alt="Thank you"/>
                        </div>

                        <div className="error-404-content col-md-6 col-12 order-2 order-md-1 text-md-left">
                            <h1>Thank you!</h1>
                            <h3>Have a wonderful day 🌸</h3>
                            <Link to={`${process.env.PUBLIC_URL + '/'}`}>Back To Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankyouPage;
